.credit-card {
    width: 320px;
    height: 200px;
    background: #000000;
    border-radius: 15px;
    color: white;
    padding: 20px;
    font-family: Arial, sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden; /* Karelerin taşmasını önlemek için */
}

.credit-card::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    transform: rotate(45deg);
}

.credit-card::after {
    content: '';
    position: absolute;
    bottom: -30px;
    right: -30px;
    width: 100px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    transform: rotate(-45deg);
}

.card-logo {
    text-align: left;
    z-index: 99;
}

.card-number {
    font-size: 18px;
    letter-spacing: 2px;
    flex-direction: column;
    display: flex;
}

.card-details {
    display: flex;
    justify-content: space-between;
}

.card-holder,
.expiry-date {
    font-size: 14px;
    color: #C7C7C7;
}

.card-holder h3,
.expiry-date h3 {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
}

.credit-card .card-logo img {
    max-width: 150px;
}
